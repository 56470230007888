<template>
	<div>
		<div class="form-group">
			<label for="season">{{ $t('gynecologie.saison') }} *</label>
			<e-select
				:disabled="disabled"
				v-model="season"
				id="season"
				track-by="season_id"
				label="season_label"
				:placeholder="$t('monte.rechercher_saison')"
				:selectedLabel="$t('global.selected_label')"
				:options="seasons"
				:allow-empty="false"
				:show-labels="false"
				:loading="loadingSeason"
				:sortable="false"
				:class="{ 'is-invalid': errors && errors.indexOf('season') > -1 }"
			></e-select>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import ContractMixin from "@/mixins/Contract.js"

	export default {
		name: 'Saison',
		mixins: [Navigation, Tools, ContractMixin],
		props: {
			season_id:{
				type: Number,
				default: () => ( 0 )
			},
			value:{
				type: Object,
				default: () => ( null )
			},
			disabled:{
				type: Boolean,
				default: () => ( false )
			},
            onlyLastSeasons: { // Pour ne proposer que les saisons supérieures ou égales à la saison en cours
                type: Boolean,
				default: () => ( false )
            }
		},
		data () {
			return {
                seasons: [],
                loadingSeason: false,
                errors: []
			}
		},
		computed: {
			season: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			},
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				await this.loadSeason()
			},
			async loadSeason() {
				this.loadSeason = true
				const temp = await this.loadSeasons()
                
                this.seasons = []
				this.season  = null
                const default_season = temp.find(season => season.season_default == true)

                // Displayable seasons
                if(this.onlyLastSeasons) {
                    this.seasons = [default_season].concat(temp.filter(s => s.season_start >= default_season.season_end))
                }
                else {
                    this.seasons = temp
                }

                // Autoselect
				if(this.season_id != 0) {
					this.season = this.seasons.find(season => season.season_id == this.season_id)
				}
				else {
					this.season = default_season
				}

				this.loadSeason = false
			}
		}
	}
</script>